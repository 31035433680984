const { version: appVersion } = require('../../package.json');

export const environment = {
  name: 'prod',
  version: appVersion,
  build: 'prod',
  baseUrl: 'https://zdorovi.ua',
  restServer: 'zdorovi.ua',
  staticIMG: '//img.zdorovi.ua/',
  seo: 'https://seo-rest.zdorovi.ua',
  sentryTracePropagationTargets: ['zdorovi.ua'],
  production: true,
  gtmIndex: 'GTM-NN6N3Z2',
  hotjar: {
    trackingCode: 3665334,
    version: 6
  },
  index: true,
  serverLogger: {
    enable: false,
    tableBody: false,
    stringBody: false,
  },
  nameApp: 'Zdorovi.ua'
};




// винницкая - vn - 21, 22, 23, 24
// волынская - lt - 43, 44, 45
// днепропетровская - dp - 49, 50, 51, 52, 53
// донецкая - dn - 83, 84, 85, 86, 87
// житомирская - zt - 10, 11, 12, 13
// закарпатская - uz - 88, 89, 90
// запорожская - zp - 69, 70, 71, 72
// ивано-франковская - if - 76, 77, 78
// киевская - kv - 07, 08, 09
// кировоградская - kr - 25, 26, 27, 28
// луганская - lg - 91, 92, 93, 94
// львовская - lv - 79, 80, 81, 82
// николаевская - mk - 54, 55, 56, 57
// одесская - od - 65, 66, 67, 68
// полтавская - pt - 36, 37, 38, 39
// ровенская - rv - 33, 34, 35
// сумская - sm - 40, 41, 42
// тернопольская - te - 46, 47, 48
// харьковская - kh - 61, 62, 63, 64
// херсонская - ks - 73, 74, 75
// хмельницкая - km - 29, 30, 31, 32
// черкасская - ck - 18, 19, 20
// черниговская - cn - 14, 15, 16, 17
// черновицкая - cv - 58, 59, 60
